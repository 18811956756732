import React from 'react'
import Layout from '../components/layout'
import Header from '../components/Header'
import { Helmet } from 'react-helmet'
import Sidebar from '../components/Sidebar/Sidebar'

const PuroliteEn = () => (
    <Layout>
      <Helmet>
          <title>Purolite Ion Exchange Resins</title>
          <meta name='description' content='IN-ECO is an official distributor of Purolite in the Baltic countries'/>
      </Helmet>
      <Header
        styleName='header'
        title='Purolite ion exchange resins'
        />
      <div className="columns">
        <div className="column content">
          <div className="content-text">JSC "Inžinerinė ekologija" is an official distributor of Purolite in the Baltic countries. We are offering a wide range of ion-exchange resins (cation exchangers, anion exchangers, mixed materials, sorbents) for purification and treatment of municipal, industrial water and water used in specific technological manufacturing processes.</div>
          <table className="table content-table-purolite">
            <thead>
            <th>Material</th>
            <th>Application Area</th>  
            </thead>
            <tbody>
            <tr>
              <td>Purolite C100E</td>
              <td> Potable water softening, cation resin (Na+ form), food grade</td>
            </tr>
            <tr>
              <td>Purolite C100H</td>
              <td>Strong acid cation resin used for demineralization, SAC (H+ form)</td>
            </tr>
            <tr>
              <td>Purolite C104Plus</td>
              <td>Weak acid cation resin used for softening, decarbonization (dealkalization) and demineralization, WAC (H+ form)</td>
            </tr>
            <tr>
              <td>Puropack PPC100</td>
              <td>Puropack cation resin – is a narrow-sized grading (650 ± 50 µm) material used for water softening or demineralization. Puropack is a maximum performance packed bed system for high load capacity, mechanical endurance, high linear velocity applications and countercurrent regeneration (Na+ or H+ form)</td>
            </tr>
            <tr>
              <td>Purolite A520E</td>
              <td>Anion resin used for nitrate removal from potable water, (Cl- form), food grade</td>
            </tr>
            <tr>
              <td>Purolite A502PS</td>
              <td>Macroporous anion resin/sorbent used for removal of organics, colorization and odor, (Cl- form), food grade</td>
            </tr>
            <tr>
              <td>Purolite A400 OH</td>
              <td>Strong base anion resin used for demineralization, SBA (OH- form, gel)</td>
            </tr>
            <tr>
              <td>Purolite A500 OH</td>
              <td>Strong base anion resin used for demineralization, SBA (OH- form, macroporous), excellent resistance to osmotic and thermal shock, resistance to organic pollution</td>
            </tr>
            <tr>
              <td>Purolite A400 MBOH IND (blue)</td>
              <td>Anion resin with indicator used for adsorption of acid (HCl) fumes. Originally blue, the color changes to amber upon exhaustion</td>
            </tr>
            <tr>
              <td>Purolite MB400</td>
              <td>Mixed bed resin used for demineralization up to 0,1 µS/cm, ready to use mixture with ratio of 40% anion and 60% cation</td>
            </tr>
            <tr>
              <td>Purolite MB46LT</td>
              <td>Mixed bed resin used for demineralization and specific EDM manufacturing processes, ready to use mixture with ratio of 50% anion and 50% cation</td>
            </tr>
            <tr>
              <td>Purolite S108</td>
              <td>Resin used for selective removal of boron from potable water, process water and waste water, food grade</td>
            </tr>
            </tbody>
            <br/>
          </table>
          <div className="content-text">We are offering technical support and consultations for our clients. If you wish to order Purolite materials please contact us with your enquiries at info@in-eco.biz.</div>
        </div>
        <Sidebar
          analysis='Questionnaires'
          boiler='Boiler'
          cooling='Cooling'
          ro='RO'
          broshure='IN-ECO brochure'
          certificates='Certificates'
        />
      </div>

    </Layout>

)

export default PuroliteEn